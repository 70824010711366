import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Banner from 'react-js-banner';

const Home = () => {

  return (
    <>
<Banner 
  image="https://d8624710.aur-vp1.pages.dev/static/media/features-split-image-01.55762b02.png"
  imageClass="App-logo"
/>
      <Hero className="illustration-section-01" />
      <FeaturesSplit invertMobile topDivider />
      <topDivider />
    </>
  );
}

export default Home;